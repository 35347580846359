import React from 'react';
import { observer } from 'mobx-react';
import { makeObservable, observable, action } from 'mobx';
import PropTypes from 'prop-types';
import { Button, DatePicker, Row, Select, Collapse, Tabs, Modal, Tooltip as AntdTooltip } from 'antd';
import { MinusCircleOutlined, CloseOutlined, UndoOutlined } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';

import withRouter from 'src/components/withRouter';
import { SEARCH_KEYWORD_TXT } from 'src/constants';
import TopicsVM from 'src/viewModels/ProjectReports/Topics';

import TopTopicsTable from './TopTopicsTable';
import TopicModal from './TopicModal';
import TopicRankTable from './TopicRankTable';
import FeatureCategories from './FeatureCategories';
import FeatureRankTable from './FeatureRankTable';
import FeatureTopicsDrawer from './FeatureTopicDrawer';

import styles from './styles.module.css';

// 快速查看口碑數據
@observer
class Topics extends React.Component {

  @observable parentTabKey = 'topic';
  @observable childTabKey = 'chart';

  constructor(props) {
    super(props);
    makeObservable(this);
    this.vm = new TopicsVM(this.props.router.params.id);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  @action
  onParentTabChange = (key) => {
    this.parentTabKey = key;
  }

  @action
  onChildTabChange = (key) => {
    this.childTabKey = key;
  }

  lineChartColors = ['#e60049', '#0bb4ff', '#0ead69', '#ffb600', '#9b19f5', '#bc3908', '#dc0ab4', '#00b2ca', '#5c6b73']

  render() {
    const searchAngle = this.props.project?.meta?.searchAngle;

    return (
      <div className={styles.tab}>
        <Row justify="end" align="middle" style={{ position: 'relative' }}>
          <div>查詢資料時間範圍：</div>
          <DatePicker.RangePicker
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={this.vm.query.onDateChange}
            value={[this.vm.data.startDate, this.vm.data.endDate]}
            className={styles.mr}
          />
          <Button
            type="primary"
            onClick={this.vm.onSearch}
            className={styles.yellowBtn}
          >
            查詢
          </Button>
        </Row>
        <h3>專案全品牌及產品排行</h3>

        <Tabs
          defaultActiveKey="L1"
          style={{ height: 500 }}
        >
          <Tabs.TabPane tab={SEARCH_KEYWORD_TXT[searchAngle]?.L1 ?? ''} key="L1">
            <TopicRankTable
              data={this.vm.rank.L1}
              title={SEARCH_KEYWORD_TXT[searchAngle]?.L1 ?? ''}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={SEARCH_KEYWORD_TXT[searchAngle]?.L2 ?? ''} key="L2">
            <TopicRankTable
              data={this.vm.rank.L2}
              title={SEARCH_KEYWORD_TXT[searchAngle]?.L2 ?? ''}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={SEARCH_KEYWORD_TXT[searchAngle]?.L3 ?? ''} key="L3">
            <TopicRankTable
              data={this.vm.rank.L3}
              title={SEARCH_KEYWORD_TXT[searchAngle]?.L3 ?? ''}
            />
          </Tabs.TabPane>
        </Tabs>


        <h3 style={{ marginTop: 20 }}>口碑結果快速檢視</h3>

        <div className={styles.box}>
          <Row align="middle" className={styles.margin}>
            <div>主題範圍搜尋關鍵字：</div>

            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className={styles.select}
              value={this.vm.query.selectedL1Keyword}
              onChange={this.vm.query.onL1KeywordChange}
              showSearch
              optionFilterProp="children"
              placeholder={`請搜尋或選擇${SEARCH_KEYWORD_TXT[searchAngle]?.L1 ?? ''}`}
            >
              {this.vm.query.L1Keywords.map((keyword) => (
                <Select.Option
                  key={keyword._id}
                  value={keyword._id}
                >
                  {keyword.name}
                </Select.Option>
              ))}
            </Select>

            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className={styles.select}
              value={this.vm.query.selectedL2Keyword}
              onChange={this.vm.query.onL2KeywordChange}
              showSearch
              optionFilterProp="children"
              placeholder={`請搜尋或選擇${SEARCH_KEYWORD_TXT[searchAngle]?.L2 ?? ''}`}
            >
              {this.vm.query.L2Keywords.map((keyword) => (
                <Select.Option
                  key={keyword._id}
                  value={keyword._id}
                >
                  {keyword.name}
                </Select.Option>
              ))}
            </Select>

            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className={styles.select}
              value={this.vm.query.selectedL3Keyword}
              onChange={this.vm.query.onL3KeywordChange}
              showSearch
              optionFilterProp="children"
              placeholder={`請搜尋或選擇${SEARCH_KEYWORD_TXT[searchAngle]?.L3 ?? ''}`}
            >
              {this.vm.query.L3Keywords.map((keyword) => (
                <Select.Option
                  key={keyword._id}
                  value={keyword._id}
                >
                  {keyword.name}
                </Select.Option>
              ))}
            </Select>

            <Button
              shape="circle"
              type="primary"
              onClick={this.vm.query.addKeyword}
              className={styles.mr}
            >
              +
            </Button>
            <Button
              type="primary"
              className={styles.mr}
              onClick={this.vm.query.copyLastKeyword}
            >
              套用前次選擇
            </Button>
            <Button
              onClick={this.vm.query.resetKeywords}
            >
              重新選擇層級
            </Button>
          </Row>

          <div className={styles.extra}>
            主題範圍最多可選 10 項
          </div>

          <Collapse
            defaultActiveKey={['1']}
            className={styles.collapse}
          >
            <Collapse.Panel
              header={`所選主題範圍 - 範圍層級：
            ${SEARCH_KEYWORD_TXT[this.props.project?.meta?.searchAngle ?? 'brand'][this.vm.query.currentLevel]}
            (${this.vm.query.currentLevel})
            `}
              key="1"
            >
              <div className={styles.collapseBody}>
                {this.vm.query.keywordsForUI.map((keyword, index) => {
                  const map = this.vm.query.keywordMap;
                  const l1 = map.get(keyword.sid)?.name ?? '';
                  const l2 = map.get(keyword.subSid)?.name ?? '';
                  const l3 = map.get(keyword.sub2Sid)?.name ?? '';
                  return (
                    <Row
                      key={`${keyword.sid}${keyword.subSid}${keyword.sub2Sid}`}
                      align="middle"
                    >
                      <MinusCircleOutlined
                        onClick={() => this.vm.query.deleteKeyword(index)}
                        className={styles.red}
                      />
                      <div>{l1}</div>
                      <div>{l2 && `-${l2}`}</div>
                      <div>{l3 && `-${l3}`}</div>
                    </Row>
                  );
                })}
              </div>
            </Collapse.Panel>
          </Collapse>

          <Row
            className={styles.margin}
            align="middle"
            justify="space-between"
          >
            <Row align="middle">
              <div>特性關鍵字檔案：</div>
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                value={this.vm.data.featureFile}
                onChange={this.vm.query.onFileChange}
                showArrow
                placeholder="選擇特性關鍵字檔案"
                style={{ width: 300 }}
              >
                {this.vm.query.features.map((item) => (
                  <Select.Option
                    key={item.fid}
                    value={item.fid}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Row>
            <Row>
              <Button
                className={styles.mr}
                onClick={this.vm.query.resetData}
              >
                清空篩選條件
              </Button>
              <Button
                type="primary"
                onClick={this.vm.onSearch}
              >
                搜尋
              </Button>
            </Row>
          </Row>
        </div>


        <Tabs
          defaultActiveKey="topic"
          activeKey={this.vm.currentTabKey}
          onChange={this.onParentTabChange}
          onTabClick={this.vm.onTabChange}
        >
          <Tabs.TabPane
            tab="主題"
            key="topic"
          >

            <Tabs
              defaultActiveKey="chart"
              onChange={this.onChildTabChange}
            >
              <Tabs.TabPane
                tab="主題口碑數每日折線圖"
                key="chart"
              >
                <div
                  style={{ height: 'calc(100vh - 190px)', width: '100%' }}
                >
                  {(this.childTabKey === 'chart' && this.parentTabKey === 'topic') && (
                    <ResponsiveContainer
                      width="100%"
                      height="100%"
                    >
                      <LineChart
                        data={this.vm.chart.data}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {this.vm.chart.rawData.map((data, index) => (
                          <Line
                            type="monotone"
                            dataKey={data.name}
                            key={data.sid}
                            stroke={this.lineChartColors[index % 10]}
                          />
                        ))}
                        <Brush />
                      </LineChart>
                    </ResponsiveContainer>
                  )}
                </div>
              </Tabs.TabPane>



              <Tabs.TabPane
                tab="主題 TOP 100 話題"
                key="top"
              >
                <div style={{ height: 'calc(100vh - 190px)' }}>
                  <TopTopicsTable
                    data={this.vm.topTopics.data}
                    isLoading={this.vm.topTopics.isLoading}
                    searchAngle={searchAngle}
                    showTopicModal={this.vm.showTopicModal}
                  />
                </div>
              </Tabs.TabPane>
            </Tabs>

          </Tabs.TabPane>


          <Tabs.TabPane
            tab="特性群組"
            key="featureGroup"
            disabled={!this.vm.query.featureCategories.currentFeatureFile}
          >
            <FeatureCategories
              featureCategories={this.vm.query.featureCategories}
              onSearch={this.vm.getTopFeatures}
            />
            <FeatureRankTable
              data={this.vm.filteredTopFeatures}
              isLoading={this.vm.topFeatures.isLoading}
              showFeatureTopicDrawer={this.vm.showFeatureTopicDrawer}
              size={this.vm.topFeatures.size}
              onFeatureItemClick={this.vm.onFeatureItemClick}
              timeString={`${this.vm.data.startDate?.format('YYYY-MM-DD')} ~ ${this.vm.data.endDate?.format('YYYY-MM-DD')}`}
              isDownloading={this.vm.topFeatures.isDownloading}
              onDownloadChart={this.vm.onDownloadChart}
              onDownloadTable={this.vm.onDownloadTable}
              onOpenBlockedListModal={this.vm.onBlockListModalOpen}
            />
          </Tabs.TabPane>
        </Tabs>


        <FeatureTopicsDrawer
          vm={this.vm.featureTopics}
          showTopicModal={this.vm.showTopicModal}
          hide={this.vm.hideFeatureTopicDrawer}
          onBottom={this.vm.featureTopicOnBottom}
          startDate={this.vm.data.startDate}
          endDate={this.vm.data.endDate}
        />


        <TopicModal
          vm={this.vm.topicModal}
          hide={this.vm.hideTopicModal}
          onBottom={this.vm.topicMadalOnBottom}
        />

        <Modal
          visible={this.vm.topFeatures.isBlockedModalOpen}
          onCancel={this.vm.hideBlockedModal}
          centered
          footer={null}
          closable={false}
          width={400}
          className={styles.blockedModal}
        >
          <div className={styles.wordModalContainer}>
            <div className={styles.wordModalTitle}>
              已選擇關鍵字
            </div>
            <div className={styles.wordModalContent}>
              {this.vm.topFeatures.selectedItem?.name}
            </div>
            <div className={styles.wordModalFooter}>
              <Button
                type="default"
                onClick={this.vm.hideBlockedModal}
                className={styles.wordModalFooterButton}
              >
                取消
              </Button>
              <Button
                type="primary"
                onClick={this.vm.onBlockWord}
                className={styles.wordModalFooterButton}
              >
                封鎖
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          visible={this.vm.topFeatures.isBlockListModalOpen}
          onCancel={this.vm.onBlockListModalClose}
          centered
          footer={null}
          closable={false}
          className={styles.blockListModal}
          width={600}
        >
          <div className={styles.blockListModalContainer}>
            <div className={styles.blockListModalHeader}>
              <div className={styles.blockListModalTitle}>查看屏蔽詞</div>
              <CloseOutlined
                onClick={this.vm.onBlockListModalClose}
                className={styles.closeIcon}
              />
            </div>
            <div className={styles.blockListModalContent}>
              <div className={styles.blockListModalContentHeaderRow}>
                <div className={styles.blockListModalContentRowItem}>
                  關鍵字
                </div>
                <div className={styles.blockListModalContentRowItem}>
                  動作
                </div>
              </div>
              {
                this.vm.showBlockListItem.length === 0
                  ? (
                    <div className={styles.emptyBlockList}>
                      無屏蔽詞
                    </div>
                  )
                  : (
                    <div className={styles.blockListModalKeywordContainer}>
                      {this.vm.showBlockListItem.map((item) => (
                        <div
                          className={styles.blockListModalContentRow}
                          key={item.fid}
                        >
                          <div className={styles.blockListModalContentRowItem}>
                            {item.name}
                          </div>
                          <div className={styles.blockListModalContentRowItem}>
                            <AntdTooltip title="復原">
                              <Button
                                type="link"
                                size="small"
                                onClick={() => this.vm.onUnblockWord(item.fid)}
                              >
                                <UndoOutlined />
                                復原
                              </Button>
                            </AntdTooltip>
                          </div>
                        </div>
                      ))}
                    </div>
                  )
              }
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

Topics.propTypes = {
  project: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

export default withRouter(Topics);
