import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Table, Row, Button, Spin, Tooltip, Collapse, Switch } from 'antd';
import { StopOutlined, DownloadOutlined, SaveOutlined } from '@ant-design/icons';

import CloudChart from './CloudChart';

import styles from './styles.module.css';

// Top100特性關鍵字話題列表

/**
 * 特性關鍵字話題列表
 * data structure:
 * {
 *   fid: string,
 *   name: string,
 *   count: number,
 *   groupName: string
 * }
 */

@observer
class FeatureRankTable extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: <Row justify="center">排行</Row>,
        key: 'index',
        width: 80,
        render: (data, _, i) => (<Row justify="center">{i + 1}</Row>)
      },
      {
        title: <Row justify="center">子群組</Row>,
        key: 'groupName',

        render: (data) => (<div>{data.groupName}</div>)
      },
      {
        title: <Row justify="center">關鍵字</Row>,
        key: 'name',
        render: (data) => (
          <div>
            <Button
              type="link"
              onClick={() => this.props.showFeatureTopicDrawer(data.fid)}
            >
              {data.name}
            </Button>
          </div>
        )
      },
      {
        title: <Row justify="center">UID</Row>,
        key: 'fid',
        width: 250,
        render: (data) => (<Row justify="center">{data.fid}</Row>)
      },
      {
        title: <Row justify="center">提及數</Row>,
        key: 'count',
        width: 180,
        render: (data) => (<Row justify="center">{data.count}</Row>)
      }
    ];
  }

  render() {
    const { data, isLoading, size, onFeatureItemClick, timeString, isDownloading, onDownloadChart, onDownloadTable, onOpenBlockedListModal } = this.props;
    return (
      <div className={clsx(styles.featureRankTablePageContainer, isLoading && styles.loading)}>
        <div
          id="download-cloud-target"
          className={styles.downloadCloudTarget}
        >
          <h3 className={styles.pageTitle}>
            {size ? `Top${size}特性關鍵字` : '特性關鍵字'}
          </h3>
          <div className={clsx(styles.cloudChartHeader, data.length === 0 && styles.disabledHeader)}>
            <div className={styles.timeString}>
              {`時間區間：${timeString || ''}`}
            </div>
            <div className={clsx(styles.cloudChartActionsContainer, isDownloading && styles.disabledActionsContainer)}>
              <Tooltip title="查看屏蔽名單">
                <Button
                  className={clsx(styles.cloudChartActionsButton)}
                  onClick={onOpenBlockedListModal}
                >
                  <StopOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="下載數據">
                <Button
                  className={clsx(styles.cloudChartActionsButton)}
                  onClick={onDownloadTable}
                >
                  <DownloadOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="下載圖檔">
                <Button
                  className={clsx(styles.cloudChartActionsButton)}
                  onClick={onDownloadChart}
                >
                  <SaveOutlined />
                </Button>
              </Tooltip>
            </div>
          </div>
          <CloudChart list={data} onCloudItemClick={onFeatureItemClick} />
        </div>
        <Collapse
          style={{ backgroundColor: '#fff', border: 'none' }}
        >
          <Collapse.Panel
            key="1"
            header={(
              <div className={styles.collapseHeader}>
                <Switch />
              </div>
            )}
            style={{ backgroundColor: '#fff', border: 'none', marginLeft: 'auto' }}
            collapsible="header"
            showArrow={false}
          >
            <Table
              rowKey="fid"
              dataSource={data}
              columns={this.columns}
              size="small"
              pagination={false}
              scroll={{ y: 'calc(100vh - 190px)' }}
            />
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  }
}

FeatureRankTable.propTypes = {
  data: PropTypes.array.isRequired,
  showFeatureTopicDrawer: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  size: PropTypes.number,
  onFeatureItemClick: PropTypes.func,
  timeString: PropTypes.string,
  isDownloading: PropTypes.bool.isRequired,
  onDownloadChart: PropTypes.func,
  onDownloadTable: PropTypes.func,
  onOpenBlockedListModal: PropTypes.func
};

FeatureRankTable.defaultProps = {
  size: undefined,
  onFeatureItemClick: () => { },
  timeString: '1993-12-29 ~ 1993-12-29',
  onDownloadChart: () => { },
  onDownloadTable: () => { },
  onOpenBlockedListModal: () => { }
};

export default FeatureRankTable;
