import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeObservable, observable, action, runInAction } from 'mobx';
import { Button, Row, Select, Collapse, Col } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';

import styles from './styles.module.css';

@observer
export default class FeatureCategories extends React.Component {
  @observable isOpen = false;
  @observable selectedCategory = null;
  @observable selectedSubCategory = null;
  @observable size = 100;

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  onCategoryChange = (category) => {
    this.selectedCategory = category;
  }

  @action
  onSubCategoryChange = (category) => {
    this.selectedSubCategory = category;
  }

  @action clear = () => {
    this.selectedCategory = null;
    this.selectedSubCategory = null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.featureCategories.currentFeatureFile !== prevProps.featureCategories.currentFeatureFile) {
      this.clear();
    }
  }

  render() {
    const featureCategories = this.props.featureCategories;
    return (
      <div className={clsx([styles.mb, styles.padding])}>
        <Collapse
          expandIcon={null}
          bordered={false}
          className={styles.mb}
          onChange={(activeKeys) => {
            if (activeKeys.find((key) => key === '1')) {
              runInAction(() => { this.isOpen = true; });
            } else {
              runInAction(() => { this.isOpen = false; });
            }
          }}
        >
          <Collapse.Panel
            header={(
              <div className={styles.panelHeader}>
                <div className={styles.mr}>
                  {`${this.isOpen ? '收起' : '展開'}完整特性關鍵字群組`}
                </div>
                {this.isOpen ? <UpOutlined /> : <DownOutlined />}
              </div>
            )}
            key="1"
            style={{ background: '#1890FF' }}
            showArrow={false}
          >
            {featureCategories.level === 2 && (
              <div className={styles.box}>

                <div className={styles.boxHeader}>
                  特性群組 (統計數字僅供參考用，因未去除重複可能與trend等報表加總不同。)
                </div>

                <div className={styles.table}>
                  {featureCategories.data.map((category) => (
                    <div
                      className={styles.cell}
                      key={category.cid}
                    >
                      <Button
                        type="link"
                        onClick={() => {
                          this.onCategoryChange(category.cid);
                          this.onSubCategoryChange(null);
                        }}
                      >
                        {`(${category.count}) ${category.name}`}
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            )}


            {featureCategories.level === 3 && (
              <div className={styles.box}>

                <Row>
                  <Col style={{ width: 240 }} className={styles.boxHeader}>特性群組</Col>
                  <Col flex={1} className={styles.boxHeader}>子群組</Col>
                </Row>

                {featureCategories.data.map((category) => (
                  <Row
                    wrap={false}
                    className={styles.row}
                    key={category.cid}
                  >
                    <Col className={styles.leftCol}>
                      <Button
                        type="link"
                        className={styles.verticalCenter}
                        onClick={() => {
                          this.onCategoryChange(category.cid);
                          this.onSubCategoryChange(null);
                        }}
                      >
                        {`(${category.count}) ${category.name}`}
                      </Button>
                    </Col>

                    <Col className={clsx([styles.rightCol, styles.table])}>
                      {category.subCategory.map((sub) => (
                        <div
                          className={styles.cell2}
                          key={sub.id}
                        >
                          <Button
                            type="link"
                            onClick={() => {
                              this.onCategoryChange(category.cid);
                              this.onSubCategoryChange(sub.id);
                            }}
                          >
                            {`(${sub.count}) ${sub.name}`}
                          </Button>
                        </div>
                      ))}

                    </Col>
                  </Row>
                ))}
              </div>
            )}
          </Collapse.Panel>
        </Collapse>

        <div className={styles.actionsContainer}>
          <div className={styles.selectContainer}>
            <div className={styles.selectLabel}>特性群組：</div>
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className={styles.selectItem}
              value={this.selectedCategory}
              onChange={(value) => {
                this.onCategoryChange(value);
                this.onSubCategoryChange(null);
              }}
            >
              {featureCategories.data.map((category) => (
                <Select.Option
                  key={category.cid}
                  value={category.cid}
                >
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className={styles.selectContainer}>
            <div className={styles.selectLabel}>子群組：</div>
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className={styles.selectItem}
              value={this.selectedSubCategory}
              onChange={this.onSubCategoryChange}
              disabled={featureCategories.level === 2}
            >
              {featureCategories.data
                ?.find((category) => category.cid === this.selectedCategory)
                ?.subCategory
                ?.map((subCategory) => (
                  <Select.Option
                    key={subCategory.id}
                    value={subCategory.id}
                  >
                    {subCategory.name}
                  </Select.Option>
                ))}
            </Select>
          </div>

          <div className={clsx(styles.selectContainer, styles.smallSelectContainer)}>
            <div className={styles.selectLabel}>數量：</div>
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              className={styles.selectItem}
              value={this.size}
              onChange={(value) => {
                this.size = value;
              }}
            >
              <Select.Option
                key={50}
                value={50}
              >
                50
              </Select.Option>
              <Select.Option
                key={100}
                value={100}
              >
                100
              </Select.Option>
            </Select>
          </div>

          <div className={styles.submitActionsContainer}>
            <Button
              onClick={this.clear}
              className={styles.mr}
            >
              清空篩選條件
            </Button>
            <Button
              type="primary"
              onClick={() => this.props.onSearch(this.selectedCategory, this.selectedSubCategory, this.size)}
            >
              套用
            </Button>
          </div>
        </div>

      </div>
    );
  }

}

FeatureCategories.propTypes = {
  featureCategories: PropTypes.shape({
    data: PropTypes.array,
    level: PropTypes.number,
    currentFeatureFile: PropTypes.string
  }).isRequired,
  onSearch: PropTypes.func.isRequired
};
