import { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import WordCloud from 'wordcloud';
import { ChartColors } from 'src/constants';

import styles from './styles.module.css';

/**
 * 文字雲
 *
 * list: [
 *  {
 *    fid: number -> 'id',
 *    name: string -> '關鍵字',
 *    count: number -> 提及次數,
 *    groupName: string -> '子群組'
 *  }
 * ]
 *
 * availableList: [
 *  {
 *    name: string -> '關鍵字',
 *    showSize: number -> 顯示大小,
 *    count: number -> 提及次數,
 *    fid: string | number -> 'id'
 *  }
 * ]
 */

export default function CloudChart({
  list = [],
  onCloudItemClick
}) {
  const [colorMap, setColorMap] = useState(new Map());

  const availableList = useMemo(() => {
    if (!list || list.length === 0) {
      return [];
    }
    const result = [];
    const countsList = list.map((item) => item.count);
    const maxRatio = 2;
    const basicSize = 15;
    const max = Math.max(...countsList);
    const min = Math.min(...countsList);

    for (let i = 0; i < list.length; i += 1) {
      setColorMap((prev) => {
        prev.set(list[i].name, ChartColors[i % ChartColors.length]);
        return prev;
      });

      if (min === max) {
        result.push([list[i].name, basicSize * maxRatio, list[i].count, list[i].fid]);
      } else {
        result.push([list[i].name, Math.ceil(((list[i].count - min) * (maxRatio / (max - min)) + 1) * basicSize), list[i].count, list[i].fid]);
      }
    }

    return result;
  }, [list]);


  const config = useMemo(() => {

    return {
      list: availableList,
      fontFamily: 'Noto Sans TC',
      rotateRatio: 0,
      color: (word) => colorMap.get(word) || '#000',
      fontWeight: 600,
      classes: 'words-item',
      shrinkToFit: true,
      click: (item) => onCloudItemClick(item)
    };
  }, [availableList, colorMap, onCloudItemClick]);

  const onDrawCloud = useCallback(() => {
    const cloudContainer = document.getElementById('topsFeatureCloudChart');
    if (!cloudContainer) {
      return;
    }

    WordCloud(cloudContainer, config);

    cloudContainer.addEventListener('wordclouddrawn', (e) => {
      const arr = Array.prototype.slice.call(e.target.children);
      const target = arr[arr.length - 1];
      const tooltip = document.createElement('DIV');
      const times = e.detail.item[2];

      tooltip.innerText = `提及次數 ：${times} `;
      tooltip.classList.add('tooltip');
      target.appendChild(tooltip);
    });

  }, [config]);

  const onMouseOverWord = (target) => {
    const isWord = target.classList.contains('words-item');
    if (!isWord) {
      return;
    }
    target.classList.add('words-item-hover');
  };

  const onMouseOutWord = (target) => {
    const isActive = target.classList.contains('words-item-hover');
    if (!isActive) {
      return;
    }
    target.classList.remove('words-item-hover');
  };

  useEffect(() => {
    onDrawCloud();
    const container = document.getElementById('topsFeatureCloudChart');
    if (container) {
      container.addEventListener('mouseover', (e) => { onMouseOverWord(e.target); });
      container.addEventListener('mouseout', (e) => { onMouseOutWord(e.target); });
    }
    return () => {
      if (container) {
        container.removeEventListener('mouseover', (e) => { onMouseOverWord(e.target); });
        container.removeEventListener('mouseout', (e) => { onMouseOutWord(e.target); });
      }
    };
  }, [onDrawCloud]);

  return (
    <div className={styles.mainContainer}>
      <div id="topsFeatureCloudChart" className={styles.cloudChart} />
      {
        availableList.length === 0 && (
          <div className={styles.noData}>
            <h3>
              暫無資料
            </h3>
          </div>
        )
      }
    </div>
  );
}

CloudChart.propTypes = {
  list: PropTypes.array,
  onCloudItemClick: PropTypes.func
};

CloudChart.defaultProps = {
  list: [],
  onCloudItemClick: () => {}
};
